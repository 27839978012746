import React from 'react';
import type { CardProps } from '@wolfejs/ui/Card';
import { Card } from '@wolfejs/ui/Card';
import { UIColors, UISizes } from '@wolfejs/ui/types/UI';
import { cn } from '@wolfejs/ui/utils/classnames';
import { UploadIcon } from '../../icons/IconMap';

export type AdminDashboardPanelProps = CardProps & {
  title?: React.ReactNode;
};

const settingsOptions = [
  {
    text: 'Option 1',
    icon: <UploadIcon />,
    id: 'option1',
  },
  {
    text: 'Option 2',
    icon: <UploadIcon />,
    id: 'option2',
  },
];

export const AdminDashboardPanel = ({
  title,
  size = UISizes.xl,
  className,
  classNames,
  color = UIColors.default,
  children,
  ...otherProps
}: AdminDashboardPanelProps) => {
  return (
    <Card
      title={title}
      noPad
      color={color}
      className={cn(`dark:bg-${color}-900 bg-white`, className)}
      elevation={3}
      classNames={{
        ...classNames,
        content: cn(classNames?.content),
        title: cn(
          'font-bold',
          {
            ['text-xs']: size === UISizes.xs,
            ['text-sm']: size === UISizes.sm,
            ['text-base']: size === UISizes.md,
            ['text-2xl']: size === UISizes.xl,
          },
          classNames?.title
        ),
        wrapper: cn('p-5 ', classNames?.wrapper),
      }}
      {...otherProps}
      // actions={
      //   <>
      //     <PopoverMenu placement="bottom-end" useClick trigger={<CogIcon />} items={settingsOptions} />
      //   </>
      // }
    >
      {children}
    </Card>
  );
};

import bbb from '@wolfejs/assets/bbb.png';
import pci_compliant_badge from '@wolfejs/assets/pci_compliant_badge.png';
import { WolfeMerchantSolutionsIcon } from '@wolfejs/icons/IconMap';
import { AdminDashboardPanel } from '@wolfejs/ms-ui/AdminDashboardPanel';
import type { MainMenuItemRecursive } from '@wolfejs/ui/Menu';
import cn from 'classnames';
import Image from 'next/image';
import { FooterLinks } from './FooterLinks';

type AppFooterProps = {
  navItems: MainMenuItemRecursive[];
};

export const AppFooter = ({ navItems }: AppFooterProps) => {
  return (
    <AdminDashboardPanel className={cn('mt-4')} classNames={{ wrapper: 'p-8' }}>
      <div className={cn(' flex flex-shrink-0 flex-col gap-8 @container')}>
        <div className={cn('relative flex w-auto flex-col justify-center gap-4 @xl:flex @xl:flex-row')}>
          <div className={cn('mx-auto w-full min-w-[167px] max-w-[267px]')}>
            <WolfeMerchantSolutionsIcon className="inline-block max-w-[100%]" />
          </div>

          <FooterLinks items={navItems} />
          <div className={cn('mt-4 flex max-h-[32px] flex-shrink-0 justify-center gap-2 align-middle @xl:mt-0')}>
            <Image
              src={pci_compliant_badge.src}
              style={{ width: 76, height: 30 }}
              height={30}
              alt="PCI compliant badge"
              width={76}
            />
            <Image src={bbb.src} alt="Credited Business" style={{ width: 81, height: 30 }} height={30} width={81} />
          </div>
        </div>
        <div className={cn('flex justify-center text-sm font-medium text-default-900 dark:text-foreground')}>
          &copy; {new Date().getFullYear()} The Wolfe Companies, LLC. All Rights Reserved
        </div>
      </div>
    </AdminDashboardPanel>
  );
};

'use client';

import { AnonLayout } from '@/components/AppLayout/AnonLayout';
import { logger } from '@/utils/logger';
import { useEffect } from 'react';

export default function Error({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
  const currentTimestamp = new Date().toLocaleString();

  useEffect(() => {
    logger.error(error);
  }, [error]);

  return (
    <AnonLayout hideHeaderButtons>
      <div className="bg-gray-100 flex min-h-screen items-center justify-center">
        <div className="w-full max-w-md rounded-lg bg-white p-6 shadow-lg">
          <h1 className="text-red-600 mb-4 text-2xl font-bold">Oops! Something went wrong.</h1>
          <p className="mb-4">We encountered an unexpected issue. Please try the following steps:</p>
          <ul className="mb-4 list-inside list-disc">
            <li>
              <strong>Refresh the page</strong> or restart the application.
            </li>
            <li>
              <strong>Check your internet connection</strong> to ensure it is stable.
            </li>
            <li>
              <strong>Clear your browser cache</strong> or application data.
            </li>
          </ul>
          <p className="mb-4">
            If the problem persists, please contact our support team with the following information:
          </p>
          <div className="bg-gray-100 mb-4 rounded p-4">
            <p>
              <strong>Error Code: </strong>
              {error?.digest}
            </p>
            <p>
              <strong>Timestamp:</strong> {currentTimestamp}
            </p>
            <p>
              <strong>Description:</strong> Briefly describe what you were doing when the error occurred.
            </p>
          </div>
          <p>We apologize for the inconvenience and appreciate your patience as we work to resolve this issue.</p>
          <button onClick={() => reset()}>Try again</button>
        </div>
      </div>
    </AnonLayout>
  );
}

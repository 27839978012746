import { ErrorBoundary } from '@wolfejs/ui/ErrorBoundary/ErrorBoundary';
import type { UIComponentProps } from '@wolfejs/ui/types/UI';
import cn from 'classnames';
import { AnonHeader } from './AnonHeader';
import { AppFooter } from './AppFooter';
import { UILayout } from './UILayout';
import { footerNavItems } from './navItems';

export type AnonLayoutProps = UIComponentProps & {
  hideHeaderButtons?: boolean;
};

export const AnonLayout = ({ className, children, hideHeaderButtons = false, ...otherProps }: AnonLayoutProps) => {
  return (
    <html>
      <UILayout>
        <div id="app-container" className={cn('flex min-h-screen flex-col bg-background', className)} {...otherProps}>
          <div id="header-container" className={cn('sticky top-0 z-10 w-full bg-white dark:bg-default-900')}>
            <AnonHeader hideButtons={hideHeaderButtons} />
          </div>
          <div id="body-container" className={cn('w-full flex-1 bg-background')}>
            <ErrorBoundary>{children}</ErrorBoundary>
          </div>
          <div id="footer-container" className={cn('container w-full bg-background p-4 @container @4xl:mt-8')}>
            <AppFooter navItems={footerNavItems} />
          </div>
        </div>
      </UILayout>
    </html>
  );
};

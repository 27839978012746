import { routes } from '@/routes';
import { Bank, Cog, ReceiptDollar, Reports, Support, Template } from '@wolfejs/icons/IconMap';
import type { MainMenuItemRecursive } from '@wolfejs/ui/Menu';

export const appHeaderNavItems: MainMenuItemRecursive[] = [
  {
    text: 'Dashboard',
    url: routes.home,
    icon: <Template className="text-default-400" />,
  },
  {
    text: 'Orders',
    url: routes.orders,
    icon: <ReceiptDollar className="text-default-400" />,
  },
  {
    text: 'Funds',
    url: routes.funds,
    icon: <Bank className="text-default-400" />,
  },
  {
    text: 'Reporting',
    url: routes.reports,
    icon: <Reports className="text-default-400" />,
  },
  {
    text: 'Products',
    url: routes.products,
    icon: <Template className="text-default-400" />,
  },
];

export const footerNavItems: MainMenuItemRecursive[] = [];

export const popoverUserMenuItems: MainMenuItemRecursive[] = [
  {
    text: 'User Settings',
    id: 'settings',
    url: routes.userAccount,
  },
  {
    text: 'Sign Out',
    id: 'logout',
  },
];

export const helperMenuItems: MainMenuItemRecursive[] = [
  {
    text: 'Settings',
    url: routes.userAccount,
    icon: <Cog className="text-default-400" />,
  },
  {
    text: 'Help',
    url: 'ms-dev-support@wolfe.com',
    icon: <Support className="text-default-400" />,
  },
];
